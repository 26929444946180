import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import CategoryNav from '../components/Post/CategoryNav';
import PostContentGroup from '../components/Post/ContentGroup';
import Paginator from '../components/Paginator';
 
const BlogPage = ({data, pageContext}) => {

    // condense the posts
    const posts = data.allWpPost.edges.map(({node}) => node);

    const { numPages, currentPage } = pageContext;
    
    return (
        <Layout>
            <SEO />
            <Container tw="grid mt-10 md:(grid-cols-3) lg:(grid-cols-4) xl:(grid-cols-5) gap-8">

                {/* Sticky Categories Nav */}
                <div tw="h-full relative">
                    <CategoryNav />
                </div>

                {/* POSTS SECTION */}
                <div tw="md:(col-span-2) lg:(col-span-3) xl:(col-span-4)">
              
                    <PostContentGroup posts={posts}/>
                    <div tw="flex justify-end">

                        <Paginator 
                        urlPrefix="/learn/blog/page/"
                            pageOneSlug="/learn/blog"
                            totalPages={numPages} 
                            currentPage={currentPage} 
                        />
                    </div>
                    
                </div>

            </Container>
        </Layout>
    )
}

export const query = graphql`
    query postPaginationQuery($skip: Int!, $limit: Int!){
        allWpPost(
            limit: $limit 
            skip: $skip 
            sort: {order: DESC, fields: date}
        ) {
            edges {
                node {
                    date
                    excerpt
                    title
                    slug
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    fluid(maxHeight: 800) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    categories {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
            
        }
    }
`

export default BlogPage