import React from 'react'
import tw from 'twin.macro'
import Excerpt from './Excerpt'
import { H3 } from '../Headings'

const ContentGroup = ({ posts }) => {
  if (posts.length) {
    const [firstPost, ...remainingPosts] = posts
    return (
      <div tw="lg:(grid grid-cols-2 col-gap-8 row-gap-20)">
        {/* Masthead post -- the most recent result */}
        <Excerpt
          tw="col-span-2"
          date={firstPost.date}
          title={firstPost.title}
          slug={firstPost.slug}
          categories={firstPost.categories}
          featuredImage={firstPost.featuredImage}
          excerpt={firstPost.excerpt}
        />

        {/* Remaining Posts */}
        {remainingPosts.length
          ? remainingPosts.map(post => (
              <Excerpt
                date={post.date}
                title={post.title}
                slug={post.slug}
                categories={post.categories}
                featuredImage={post.featuredImage}
                excerpt={post.excerpt}
              />
            ))
          : null}
      </div>
    )
  }

  return <H3>No posts found</H3>
}
export default ContentGroup
