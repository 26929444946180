import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { H2 } from '../Headings'
import tw from 'twin.macro'
import { ButtonLink } from '../Button'
import A from '../A'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import MetaContent from './MetaContent'
import Logos from '../Logos'

const PostExcerpt = props => {
  const { title, date, categories, featuredImage, excerpt, slug } = props
  return (
    <article {...props}>
      <H2 tw="mb-0">{title}</H2>
      <MetaContent
        tw="mb-4"
        publishedDate={date}
        categories={categories.nodes}
      />
      <div
        css={[
          'padding-bottom:56.25%;',
          tw`bg-gray-50 border border-gray-100 w-full h-0 relative mb-4`,
        ]}
      >
        {featuredImage ? (
          <Img
            fluid={featuredImage?.node?.localFile.childImageSharp.fluid}
            css={[
              'top:50%;position:absolute!important;',
              tw`transform -translate-y-1/2 w-full`,
            ]}
          />
        ) : (
          <Logos.WithCookie
            css={[
              'width: 10em; top:50%; left:50%;',
              tw`absolute transform -translate-x-1/2 -translate-y-1/2`,
            ]}
          />
        )}
      </div>
      <p
        tw="mb-4 text-gray-700"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <ButtonLink to={`/learn/blog/${slug}`} large>
        <span tw="sr-only">Read {title}</span>
        Read More <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
      </ButtonLink>
    </article>
  )
}

export default PostExcerpt
